import Component from "vue-class-component";
import Vue from "vue";
import { useVuelidate, ValidationRule } from "@vuelidate/core";

interface Form<Fields extends string> {
  model: FormValues<Fields>;
  errors: FormErrors<Fields>;
  isValid: boolean;
  touch: (field: Fields) => void;
}

type FormValues<Fields extends string> = {
  [K in Fields]: string;
};

type FormErrors<Fields extends string> = {
  [K in Fields]: string;
};

export type FormOption<Fields extends string> = {
  [K in Fields]?: ValidationRules;
};

type ValidationRules = {
  [K in string]: ValidationRule;
};

@Component({
  setup() {
    return { v$: useVuelidate() };
  },
})
export default class FormMixin<Fields extends string> extends Vue {
  private form: Record<Fields, string> = {} as unknown as FormValues<Fields>;

  beforeCreate() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$options.validations = () => {
      const { rules, initialValues } = this.initForm();
      const formValidations = Object.fromEntries(
        Object.keys(initialValues).map(key => [key, (rules && rules[key]) ?? {}])
      );
      this.form = initialValues;
      return { form: formValidations };
    };
  }

  initForm(): { rules?: FormOption<Fields>; initialValues: FormValues<Fields> } {
    throw new Error("initForm must be implemented");
  }

  get $form(): Form<Fields> {
    const errors = Object.fromEntries(
      this.fields.map(key => {
        const field = this.vuelidate.form[key];
        return [key, field.$error ? field.$errors[0].$message : ""];
      })
    ) as FormErrors<Fields>;

    return { model: this.form, errors, touch: this.touch, isValid: !this.vuelidate.form.$invalid };
  }

  private get vuelidate() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.v$;
  }

  private get fields() {
    return Object.keys(this.vuelidate.form).filter(key => !key.startsWith("$"));
  }

  private touch(fieldName: string) {
    this.vuelidate.form[fieldName].$touch();
  }
}
